import React from "react";
import "./Newsection2.css";
import sec6img from "../../../../assets/why_wait.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";
import { Link } from "react-scroll";

const Index = () => {
  return (
    <div className="Newsection2">
      <div className="submain_Newsection2_div">
        <div className="Newsection2_text_div">
          <h1>Why Wait ?</h1>
          <p>
            <span>Discover New Markets: </span>Access a vast network of
            businesses and consumers across Africa and Türkiye.
          </p>
          <p>
            <span>Forge Strategic Partnerships: </span>
            Connect directly with potential partners, investors, and business
            associations.
          </p>
          <p>
            <span>Chat for Free: </span>
            Start conversations instantly with businesses that share your
            vision—at no cost.
          </p>
          <p>
            Download the TurkAfrica B2B App <span>for FREE</span> today, and
            take the first step towards expanding your business globally. The
            future of your business is just a tap away!
          </p>
          <Link className="PRE_btn" to="Download" offset={-100}>
            Don't Miss Out—Join Us Now!
          </Link>
        </div>
        {/* Download */}
        <div className="Newsection2_img_div">
          <img src={sec6img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Index;
